import React, { useEffect, useState } from "react";

import { App, Layout } from "antd";

import ActiveSession from "./components/ActiveSession";
import SessionsSider from "./components/SessionsSider";
import SolverHeader from "./components/SolverHeader";
import Startup from "./components/Startup";
import TourManager from "./components/TourManager";
import { AppPath, NavigationBehavior, parseAppPath } from "./data/Navigation";
import { SolverInterfaceStatus, useSolverInterfaceContext } from "./data/SolverInterface";
import { useLoadSession } from "./data/SolverSession";
import { AuthType } from "./data/User";

const SolverApp: React.FC = () => {
    const {
        activeRepo,
        currentUser,
        loggedIn,
        connectToSolverInterface,
        onSignUpTokenInvalid,
        loadingAdditionalRepos,
        solverInterfaceStatus,
        login,
        signUpToken,
    } = useSolverInterfaceContext();
    const loadSession = useLoadSession();

    const [faqClosed, setFaqClosed] = useState(false);
    const [siderDrawerOpen, setSiderDrawerOpen] = useState(false);

    useEffect(() => {
        const initApp = async () => {
            const pathConstituents: AppPath = parseAppPath(document.location.pathname, document.location.search);
            const signUpToken = pathConstituents.signUpToken;

            if (pathConstituents.signUpTokenFailure) {
                onSignUpTokenInvalid();
            } else if (pathConstituents.repo) {
                const [org, repo] = pathConstituents.repo.split("/");

                const connectedSuccessfully = await connectToSolverInterface({
                    requestedOrg: org,
                    requestedRepo: repo,
                    signUpToken,
                });

                if (connectedSuccessfully && pathConstituents.session) {
                    loadSession({ org, repo, session_id: pathConstituents.session }, NavigationBehavior.NONE);
                }
            } else {
                await connectToSolverInterface({ signUpToken });
            }
        };

        initApp();
    }, []);

    const buildMainLayout = () => {
        if (solverInterfaceStatus !== SolverInterfaceStatus.LOGGED_IN) return buildStartup();
        if (!activeRepo) return buildStartup();

        return (
            <Layout>
                <SessionsSider drawerOpen={siderDrawerOpen} onCloseDrawer={() => setSiderDrawerOpen(false)} />
                <ActiveSession />
            </Layout>
        );
    };

    const buildStartup = () => {
        return (
            <Startup
                solverInterfaceStatus={solverInterfaceStatus}
                onLogin={(authType: AuthType) => login(authType, signUpToken)}
                haveSignUpToken={!!signUpToken}
                loadingAdditionalRepos={loadingAdditionalRepos}
                haveActiveRepo={!!activeRepo}
                currentUser={currentUser}
            />
        );
    };

    return (
        <App>
            <SolverHeader onFaqClose={() => setFaqClosed(true)} onOpenList={() => setSiderDrawerOpen(true)} />
            {buildMainLayout()}
            {loggedIn && activeRepo && faqClosed && <TourManager />}
        </App>
    );
};

export default SolverApp;
