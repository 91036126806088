export default class Features {
    static PAYMENTS_ENABLED = "PAYMENTS_ENABLED";
    static PROJECTS_UI_ENABLED = "PROJECTS_UI_ENABLED";

    static isPaymentEnabled(): boolean {
        return this.isFeatureEnabled(this.PAYMENTS_ENABLED);
    }

    static isProjectsUIEnabled(): boolean {
        return this.isFeatureEnabled(this.PROJECTS_UI_ENABLED);
    }

    private static getFeatureValue(feature: string): string {
        return window.env?.[`REACT_APP_${feature}`] || process.env[`REACT_APP_${feature}`] || "";
    }

    private static isFeatureEnabled(feature: string): boolean {
        const value = this.getFeatureValue(feature);

        return value.toLowerCase() === "true" || value === "1";
    }
}
