import React, { useEffect, useState } from "react";

import { Button, Image, Tooltip } from "antd";
import { GithubOutlined, GitlabOutlined, LoadingOutlined } from "@ant-design/icons";

import NewRepoWizard from "./NewRepoWizard";
import { Repo } from "../data/Repos";
import { SolverInterfaceStatus } from "../data/SolverInterface";
import { AuthType, User } from "../data/User";

import solverIconReverse from "../images/solver_icon_reverse_transparent.png";
import "../Startup.scss";

interface StartupProps {
    solverInterfaceStatus: SolverInterfaceStatus;
    onLogin: (authType: AuthType) => void;
    haveSignUpToken: boolean;
    haveActiveRepo: boolean;
    loadingAdditionalRepos: boolean;
    currentUser: User | undefined;
}

const Startup: React.FC<StartupProps> = ({
    solverInterfaceStatus,
    onLogin,
    haveSignUpToken,
    haveActiveRepo,
    loadingAdditionalRepos,
    currentUser,
}) => {
    const [showWelcome, setShowWelcome] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setShowWelcome(true), 500);
        return () => clearTimeout(timer);
    }, []);

    const handleLogin = (authType: AuthType) => {
        setIsLoggingIn(true);
        onLogin(authType);
    };

    const buildStartup = () => {
        if (solverInterfaceStatus !== SolverInterfaceStatus.LOGGED_IN) {
            switch (solverInterfaceStatus) {
                case SolverInterfaceStatus.ERROR:
                    return <span className="startup-text">Failed to connect to Solver</span>;
                case SolverInterfaceStatus.FAILED_TO_LOAD_REPOS:
                    return <span className="startup-text">Failed to load repos</span>;
                case SolverInterfaceStatus.SIGN_UP_TOKEN_INVALID:
                    return (
                        <span className="startup-text">
                            Sign up link invalid or expired. Please contact us for a new sign up link.
                        </span>
                    );
                case SolverInterfaceStatus.LOGGED_OUT:
                    return (
                        <div className={`startup-login-container ${isLoggingIn ? "startup-fade-out" : ""}`}>
                            <div className={`startup-welcome ${showWelcome ? "startup-fade-in" : ""}`}>
                                <Image
                                    src={solverIconReverse}
                                    preview={false}
                                    width={100}
                                    height={100}
                                    alt="Solver icon"
                                />
                                <h1 className="startup-welcome-text startup-welcome-header">Welcome to Solver</h1>
                            </div>
                            {haveSignUpToken && (
                                <div className={`startup-sign-up-banner ${showWelcome ? "startup-fade-in" : ""}`}>
                                    <p className="startup-welcome-text startup-welcome-subheader">
                                        Sign in to complete onboarding and start Solving.
                                    </p>
                                </div>
                            )}
                            <div className={`startup-button-container ${showWelcome ? "startup-fade-in" : ""}`}>
                                <Button className="startup-button" block onClick={() => handleLogin(AuthType.GitHub)}>
                                    <GithubOutlined />
                                    Log in with GitHub
                                </Button>
                            </div>
                            <div className={`startup-button-container ${showWelcome ? "startup-fade-in" : ""}`}>
                                <Tooltip placement="bottom" title="Coming Soon">
                                    <Button
                                        disabled={true}
                                        className="startup-button"
                                        block
                                        onClick={() => handleLogin(AuthType.GitLab)}
                                    >
                                        <GitlabOutlined />
                                        Log in with GitLab
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    );
                case SolverInterfaceStatus.LOADING:
                    return <LoadingOutlined style={{ fontSize: "40px" }} />;
                case SolverInterfaceStatus.USER_NOT_ALLOWLISTED:
                    return (
                        <div className="startup-text">
                            Thank you for your interest in using Solver! We're working on rolling out to more
                            organizations and individuals. Please visit{" "}
                            <a className="startup-link" href="https://solverai.com/#early-access" target="_blank">
                                https://solverai.com/#early-access
                            </a>{" "}
                            to request access.
                        </div>
                    );
            }
        } else if (solverInterfaceStatus === SolverInterfaceStatus.LOGGED_IN && !haveActiveRepo) {
            if (loadingAdditionalRepos) {
                return <LoadingOutlined style={{ fontSize: "40px" }} />;
            } else {
                return <NewRepoWizard authType={currentUser?.auth_type || AuthType.GitHub} />;
            }
        } else return null;
    };

    return <div className="startup">{buildStartup()}</div>;
};

export default Startup;
