import React from "react";
import { Button, Statistic } from "antd";
import { CreditCardOutlined, GiftOutlined } from "@ant-design/icons";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";

export interface SubscriptionData {
    remaining_subscription_usage: number;
    remaining_usage_credits: number;
    subscription_name: string;
    is_free: boolean;
}

interface SubscriptionTabProps {
    subscriptionData: SubscriptionData;
}

const SubscriptionTab: React.FC<SubscriptionTabProps> = ({ subscriptionData }) => {
    return (
        <div className="subscription-stats">
            <div className="subscription-stat-item">
                <Statistic
                    title={`${subscriptionData.subscription_name} Credits`}
                    value={subscriptionData.remaining_subscription_usage}
                    prefix={<GiftOutlined />}
                />
                <div className="settings-button-container">
                    <Button
                        type="default"
                        icon={<CreditCardOutlined />}
                        className="settings-action-button"
                        onClick={() => {
                            solverInterfaceApiAxios
                                .get("/customer_portal", { params: { return_url: window.location.href } })
                                .then((response) => (window.location.href = response.data.url))
                                .catch((error) => console.log(error));
                        }}
                    >
                        Manage Subscription
                    </Button>
                </div>
            </div>

            <div className="subscription-stat-item">
                <Statistic
                    title="Additional Usage Credits"
                    value={subscriptionData.remaining_usage_credits}
                    prefix={<CreditCardOutlined />}
                />
                <div className="settings-button-container">
                    <Button
                        type="default"
                        icon={<CreditCardOutlined />}
                        className="settings-action-button"
                        disabled={subscriptionData.is_free}
                        onClick={() => {
                            solverInterfaceApiAxios
                                .post("/credit_purchase", {
                                    quantity: 500,
                                    return_url: window.location.href,
                                })
                                .then((response) => (window.location.href = response.data.url))
                                .catch((error) => console.log(error));
                        }}
                    >
                        Purchase Credits
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionTab;
