import { GithubOutlined, GitlabOutlined } from "@ant-design/icons";
import { Button, Divider, notification, Popover, Tabs, Typography } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import Features from "../data/Features";
import { useSettingsState } from "../data/SettingsState";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import GenericOptionsTab from "./GenericOptionsTab";
import type { SubscriptionData } from "./SubscriptionTab";
import SubscriptionTab from "./SubscriptionTab";

import { useSolverInterfaceContext } from "../data/SolverInterface";
import { AuthType } from "../data/User";

import ExecutionEnvironmentForm from "./ExecutionEnvironmentForm";
import ExecutionImageForm from "./ExecutionImageForm";

import "./Settings.scss";

interface SettingsPopoverProps {
    children: ReactNode;
}

const SettingsPopover: React.FC<SettingsPopoverProps> = ({ children }) => {
    const { isOpen, setIsOpen, activeTab } = useSettingsState();
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData | null>(null);
    const { logout, loggedIn, currentUser, activeRepo } = useSolverInterfaceContext();

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            if (!Features.isPaymentEnabled()) return;

            try {
                const response = await solverInterfaceApiAxios.get("/subscription_usage");
                if (response.status !== 204) {
                    setSubscriptionData(response.data);
                }
            } catch (error) {
                console.error("Error fetching subscription data:", error);
            }
        };

        fetchSubscriptionData();
    }, [isOpen]); // Refetch when popover opens
    const [api, contextHolder] = notification.useNotification();

    const buildPopoverContent = () => {
        return (
            <div className="settings-container scrollbar scrollbar-gutter-stable-both">
                {buildCurrentUser()}
                {loggedIn && (
                    <>
                        <Divider type="horizontal" className="settings-divider" />
                        <Tabs
                            defaultActiveKey={activeTab}
                            items={[
                                ...(Features.isPaymentEnabled() && subscriptionData
                                    ? [
                                          {
                                              key: "subscription",
                                              label: "Subscription",
                                              children: (
                                                  <div className="settings-tab-content">
                                                      <SubscriptionTab subscriptionData={subscriptionData} />
                                                  </div>
                                              ),
                                          },
                                      ]
                                    : []),
                                {
                                    key: "execution",
                                    label: "Execution",
                                    children: (
                                        <div className="settings-tab-content">
                                            {activeRepo ? (
                                                <>
                                                    <ExecutionImageForm
                                                        org={activeRepo.org}
                                                        repo={activeRepo.name}
                                                        allowChanges={activeRepo.allow_modify_repo_settings}
                                                    />
                                                    <Divider type="horizontal" className="settings-divider" />
                                                    <ExecutionEnvironmentForm
                                                        org={activeRepo.org}
                                                        repo={activeRepo.name}
                                                        allowChanges={activeRepo.allow_modify_repo_settings}
                                                    />
                                                </>
                                            ) : (
                                                "Select a repo to configure execution settings"
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    key: "generic-options",
                                    label: "Settings",
                                    children: (
                                        <div className="settings-tab-content">
                                            <GenericOptionsTab />
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
                <Divider type="horizontal" className="settings-divider" />
                <Button type="primary" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
            </div>
        );
    };

    const onLogout = () => {
        logout();

        setIsOpen(false);
    };

    const authTypeIcon = (authType: AuthType) => {
        switch (authType) {
            case AuthType.GitHub:
                return <GithubOutlined />;
            case AuthType.GitLab:
                return <GitlabOutlined />;
            default:
                return null;
        }
    };

    const buildCurrentUser = () => {
        if (!currentUser) return null;

        return (
            <div className="settings-current-user">
                <div className="settings-current-user-row">
                    <div className="settings-current-user-info">
                        <Typography.Text strong>{currentUser.name}</Typography.Text>
                        {authTypeIcon(currentUser.auth_type)}
                    </div>
                    <Button onClick={onLogout}>Logout</Button>
                </div>
            </div>
        );
    };

    return (
        <>
            {contextHolder}
            <Popover
                content={buildPopoverContent()}
                open={isOpen}
                trigger="click"
                arrow={false}
                onOpenChange={setIsOpen}
                placement="bottomRight"
            >
                {children}
            </Popover>
        </>
    );
};

export default SettingsPopover;
