import React, { useEffect, useState } from "react";

import { Input, Tooltip } from "antd";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { NotificationInstance } from "antd/lib/notification/interface";

import "./ActiveSession.scss";
import "../responsive.scss";

interface ActiveSessionTitleProps {
    sessionTitle: string;
    editable: boolean;
    onUpdateTitle: (title: string) => Promise<boolean>;
    notification: NotificationInstance;
}

const ActiveSessionTitle: React.FC<ActiveSessionTitleProps> = ({
    sessionTitle,
    editable,
    onUpdateTitle,
    notification,
}) => {
    const [editing, setEditing] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [updatingTitle, setUpdatingTitle] = useState<boolean>(false);

    useEffect(() => {
        setTitle(sessionTitle);
        setEditing(false);
    }, [sessionTitle]);

    const updateTitle = async (title: string) => {
        setUpdatingTitle(true);
        const success = await onUpdateTitle(title || "Untitled Session");
        setUpdatingTitle(false);
        if (!success) {
            notification.error({ message: "Failed to update title" });
            setTitle(sessionTitle);
        }
    };

    if (editing) {
        return (
            <Input
                className="active-session-title-edit"
                value={title}
                onKeyDown={async (e) => {
                    if (e.key === "Enter" || e.key === "Escape") {
                        const value = (e.target as HTMLInputElement).value;
                        setEditing(false);
                        if (value !== sessionTitle) {
                            updateTitle(value);
                        }
                    }
                }}
                onChange={(e) => setTitle(e.target.value)}
                autoFocus={true}
                onBlur={async () => {
                    setEditing(false);
                    if (title !== sessionTitle) {
                        updateTitle(title);
                    }
                }}
            />
        );
    } else {
        return (
            <div className="active-session-title-container small-screen-hidden">
                <Tooltip title={sessionTitle} placement="bottom" arrow={false}>
                    <span className="active-session-title">{sessionTitle}</span>
                </Tooltip>
                {editable && (
                    <Tooltip title="Edit title" placement="right" arrow={false}>
                        {updatingTitle ? (
                            <LoadingOutlined />
                        ) : (
                            <EditOutlined
                                className="session-title-edit-trigger"
                                onClick={() => editable && setEditing(true)}
                            />
                        )}
                    </Tooltip>
                )}
            </div>
        );
    }
};

export default ActiveSessionTitle;
