import { ConfigProvider } from "antd";
import React from "react";
import "react-diff-view/style/index.css";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { APP_THEME } from "./AppTheme";
import { ExecutionSettingsProvider } from "./data/ExecutionSettings";
import { LocalSettingsProvider } from "./data/LocalSettingsContext";
import { NavigationProvider } from "./data/Navigation";
import { SessionBrowsingProvider } from "./data/SessionBrowsing";
import { SettingsStateProvider } from "./data/SettingsState";
import { SolverInterfaceContextProvider } from "./data/SolverInterface";
import { SolverInvocationOptionsProvider } from "./data/SolverInvocationOptions";
import { SessionProvider } from "./data/SolverSession";
import ErrorFallback from "./ErrorFallback";
import "./index.scss";
import "./scrollbar.scss";
import SolverApp from "./SolverApp";

// initTelemetry(window.location.host);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ConfigProvider theme={APP_THEME}>
                <LocalSettingsProvider>
                    <SolverInterfaceContextProvider>
                        <SolverInvocationOptionsProvider>
                            <ExecutionSettingsProvider>
                                <SettingsStateProvider>
                                    <SessionProvider>
                                        <SessionBrowsingProvider>
                                            <NavigationProvider>
                                                <SolverApp />
                                            </NavigationProvider>
                                        </SessionBrowsingProvider>
                                    </SessionProvider>
                                </SettingsStateProvider>
                            </ExecutionSettingsProvider>
                        </SolverInvocationOptionsProvider>
                    </SolverInterfaceContextProvider>
                </LocalSettingsProvider>
            </ConfigProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

document.addEventListener(
    "keydown",
    (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === "s") e.preventDefault();
    },
    false
);
