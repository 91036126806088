"use client";
import { FilterOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popover, Tooltip } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import classNames from "classnames";
import NewSessionButton from "./NewSessionButton";
import { startOfDay, subDays, subMonths, subYears } from "date-fns";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ListChildComponentProps, ListOnScrollProps, VariableSizeList } from "react-window";

import { Repo } from "../data/Repos";
import { AuthorSelectionFilter, useSessionBrowsingContext } from "../data/SessionBrowsing";
import { Session, SessionInfo } from "../data/SolverSession";
import { SessionVisibility, User } from "../data/User";
import { useDebounce } from "../hooks/useDebounce";
import FilterList from "./FilterList";
import SessionCard from "./SessionCard";
import globalStyleConstants from "../constants.module.scss";
import sessionsListStyleConstants from "./SessionsList.module.scss";
import "./SessionsList.scss";

const APP_HEADER_HEIGHT = parseInt(globalStyleConstants.APP_HEADER_HEIGHT);

const SESSIONS_LIST_HEADER_HEIGHT = parseInt(sessionsListStyleConstants.SESSIONS_LIST_HEADER_HEIGHT);
const SESSION_CARD_HEIGHT = parseInt(sessionsListStyleConstants.SESSION_CARD_HEIGHT);
const TIME_BUCKET_HEADER_HEIGHT = parseInt(sessionsListStyleConstants.TIME_BUCKET_HEADER_HEIGHT);

const groupSessionsByTimeBuckets = (sessions: Session[]) => {
    const now = new Date();
    const today = startOfDay(now);
    const yesterday = subDays(today, 1);
    const sevenDaysAgo = subDays(today, 7);
    const thirtyDaysAgo = subDays(today, 30);
    const oneYearAgo = subYears(now, 1);

    // Initialize base time buckets
    const groupedSessions: { [key: string]: Session[] } = {
        Today: [],
        Yesterday: [],
        "Previous 7 days": [],
        "Previous 30 days": [],
    };

    // Add month buckets for last 12 months
    [...Array(12)].forEach((unused, i) => {
        const monthDate = subMonths(now, i);
        const monthName = monthDate.toLocaleString("default", { month: "long" });
        groupedSessions[monthName] = [];
    });

    // Add a bucket for older sessions
    groupedSessions["Last Year"] = [];

    // Group sessions into buckets
    sessions.forEach((session) => {
        const sessionDate = new Date(session.modify_timestamp * 1000);

        if (sessionDate >= today) {
            groupedSessions["Today"].push(session);
        } else if (sessionDate >= yesterday) {
            groupedSessions["Yesterday"].push(session);
        } else if (sessionDate >= sevenDaysAgo) {
            groupedSessions["Previous 7 days"].push(session);
        } else if (sessionDate >= thirtyDaysAgo) {
            groupedSessions["Previous 30 days"].push(session);
        } else if (sessionDate >= oneYearAgo) {
            const monthName = sessionDate.toLocaleString("default", { month: "long" });
            if (groupedSessions[monthName]) {
                groupedSessions[monthName].push(session);
            }
        } else {
            groupedSessions["Last Year"].push(session);
        }
    });

    // Create new object with only non-empty buckets
    return Object.fromEntries(Object.entries(groupedSessions).filter(([, sessions]) => sessions.length > 0));
};

interface SessionsListItemData {
    items: Array<{ type: "header"; label: string } | { type: "session"; session: Session }>;
    onSwitchSession: (session: Session) => Promise<void>;
    onDeleteSession: (session: Session) => void;
    onUpdateVisibility: (session: Session, visibility: SessionVisibility) => Promise<boolean>;
    currentUser: User | undefined;
    setSize: (index: number) => void;
    activeSession?: Session;
}

const SessionsListRow = React.memo(({ index, style, data }: ListChildComponentProps<SessionsListItemData>) => {
    const item = data.items[index];
    const { activeSession, onSwitchSession, onDeleteSession, onUpdateVisibility, currentUser } = data;

    const content =
        item.type === "header" ? (
            <div className="time-bucket-header" style={style}>
                {item.label}
            </div>
        ) : (
            <li
                className={classNames("session-list-item", {
                    "session-list-item-active": item.session.session_id === activeSession?.session_id,
                })}
                onClick={(e) => {
                    // Don't switch if it's the active session or if user is selecting text
                    if (item.session.session_id === activeSession?.session_id) return;
                    if (document.getSelection()?.toString() !== "") return;

                    onSwitchSession(item.session);
                    e.preventDefault();
                }}
                style={{
                    ...style,
                    pointerEvents: item.session.session_id === activeSession?.session_id ? "none" : "auto",
                }}
            >
                <SessionCard
                    currentUser={currentUser}
                    session={item.session}
                    active={item.session.session_id === activeSession?.session_id}
                    onDelete={() => onDeleteSession(item.session)}
                    onUpdateVisibility={async (visibility) => await onUpdateVisibility(item.session, visibility)}
                />
            </li>
        );

    return content;
});

interface SessionsListProps {
    currentUser: User | undefined;
    repo: Repo;
    activeSession: Session | undefined;
    hideNewSessionButton?: boolean;
    onNewSession: (sessionInfo: SessionInfo) => Promise<void>;
    onSwitchSession: (session: Session) => Promise<void>;
    onDeleteSession: (session: Session) => void;
    notification: NotificationInstance;
    branches: string[];
    isLoading?: boolean;
}
const SessionsList: React.FC<SessionsListProps> = ({
    currentUser,
    repo,
    activeSession,
    hideNewSessionButton,
    onNewSession,
    onSwitchSession,
    onDeleteSession,
    notification,
    branches,
    isLoading,
}) => {
    const {
        sessions,
        loadingSessions,
        page,
        haveMoreSessions,
        titleFilter,
        setTitleFilter,
        authorSelectionFilter,
        setAuthorSelectionFilter,
        loadSessions,
        loadMoreSessions,
        updateVisibility,
    } = useSessionBrowsingContext();

    const debouncedLoadSessions = useDebounce(() => {
        loadSessions(repo.org, repo.name);
    }, 1000);

    useEffect(() => {
        setAuthorSelectionFilter(AuthorSelectionFilter.ALL);
    }, [repo, setAuthorSelectionFilter]);
    const [listHeight, setListHeight] = useState<number>(
        document.body.scrollHeight - APP_HEADER_HEIGHT - SESSIONS_LIST_HEADER_HEIGHT
    );

    useLayoutEffect(() => {
        const updateListHeight = () => {
            let height = window.innerHeight - APP_HEADER_HEIGHT - SESSIONS_LIST_HEADER_HEIGHT;
            setListHeight(height);
        };

        updateListHeight();

        window.addEventListener("resize", updateListHeight);

        return () => {
            window.removeEventListener("resize", updateListHeight);
        };
    }, []);

    const LOAD_MORE_THRESHOLD = 100;

    const getScrollHeight = useCallback((sessions: Session[]): number => {
        const totalSessionsHeight = sessions.length * SESSION_CARD_HEIGHT;
        const totalHeadersHeight = Object.keys(groupSessionsByTimeBuckets(sessions)).length * TIME_BUCKET_HEADER_HEIGHT;
        return totalSessionsHeight + totalHeadersHeight;
    }, []);

    const onScrollList = useCallback(
        (scrollProps: ListOnScrollProps) => {
            if (loadingSessions || !haveMoreSessions) return;

            const { scrollOffset, scrollDirection } = scrollProps;
            const scrollHeight = getScrollHeight(sessions);
            const isNearBottom = scrollHeight - scrollOffset <= listHeight + LOAD_MORE_THRESHOLD;

            if (scrollHeight > listHeight && isNearBottom && scrollDirection === "forward") {
                loadMoreSessions(repo.org, repo.name);
            }
        },
        [
            loadingSessions,
            haveMoreSessions,
            loadMoreSessions,
            repo.org,
            repo.name,
            listHeight,
            sessions,
            getScrollHeight,
        ]
    );

    const buildNewSessionButton = () => {
        return (
            <NewSessionButton
                repoOrg={repo.org}
                repoName={repo.name}
                repo={repo}
                onSessionCreated={onNewSession}
                notification={notification}
                branches={branches}
                isLoading={isLoading}
            />
        );
    };

    const listRef = useRef<VariableSizeList>(null);

    const setSize = useCallback((index: number) => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(index, false);
        }
    }, []);

    const listItems = useMemo(() => {
        const sortedGroupedSessions = groupSessionsByTimeBuckets(sessions);
        return Object.entries(sortedGroupedSessions).flatMap(([label, sessions]) => [
            { type: "header" as const, label },
            ...sessions.map((session) => ({ type: "session" as const, session })),
        ]);
    }, [sessions]);

    // Reset list when sessions change
    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [listItems]);

    const listData: SessionsListItemData = useMemo(
        () => ({
            onSwitchSession,
            onDeleteSession,
            notification,
            currentUser,
            setSize,
            items: listItems,
            activeSession,
            onUpdateVisibility: updateVisibility,
        }),
        [
            onSwitchSession,
            onDeleteSession,
            notification,
            currentUser,
            setSize,
            listItems,
            activeSession,
            updateVisibility,
        ]
    );

    const buildList = useCallback(() => {
        if (loadingSessions && page < 1) {
            return (
                <div style={{ textAlign: "center" }}>
                    <LoadingOutlined />
                </div>
            );
        }

        if (sessions.length === 0) {
            return <div style={{ textAlign: "center" }}>No sessions found</div>;
        }

        const getItemSize = (item: (typeof listItems)[number]) =>
            item.type === "header" ? TIME_BUCKET_HEADER_HEIGHT : SESSION_CARD_HEIGHT;

        return (
            <VariableSizeList
                ref={listRef}
                className="scrollbar scrollbar-gutter-stable"
                height={listHeight}
                itemCount={listItems.length}
                itemSize={(index) => getItemSize(listItems[index])}
                itemData={{ ...listData, items: listItems }}
                width="100%"
                onScroll={onScrollList}
            >
                {SessionsListRow}
            </VariableSizeList>
        );
    }, [loadingSessions, page, sessions.length, listHeight, onScrollList, listData, listItems]);

    const buildFilterList = () => {
        return <div className="session-filter-list">{buildFilterListContent()}</div>;
    };

    const buildFilterListContent = () => {
        return (
            <FilterList
                items={[
                    { key: AuthorSelectionFilter.ALL, label: "All Sessions" },
                    { key: AuthorSelectionFilter.MINE, label: "My Sessions" },
                ]}
                selectedKeys={[authorSelectionFilter]}
                onSelect={(key) => {
                    setAuthorSelectionFilter(key as AuthorSelectionFilter);

                    debouncedLoadSessions();
                }}
                buttonStyle="radio"
            />
        );
    };

    return (
        <>
            <div className="session-list-header">
                <Input
                    className="session-list-search"
                    value={titleFilter}
                    onChange={(e) => {
                        setTitleFilter(e.target.value);
                        debouncedLoadSessions();
                    }}
                    placeholder="Search sessions"
                    addonBefore={<SearchOutlined />}
                    addonAfter={
                        <Popover arrow={false} trigger={"click"} content={buildFilterList()} placement="bottomRight">
                            <Tooltip title="Filter" arrow={false} placement="right">
                                <Button type="text" size="small" icon={<FilterOutlined />} />
                            </Tooltip>
                        </Popover>
                    }
                    allowClear
                />
                {!hideNewSessionButton && buildNewSessionButton()}
            </div>
            {buildList()}
        </>
    );
};

export default SessionsList;
