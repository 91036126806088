import { ApartmentOutlined, FunctionOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import React, { useEffect, useRef, useState } from "react";
import { getBranches, pushInitialCommit } from "../data/Repos";
import "./Chat.scss";
import CustomAudioRecorder from "./CustomAudioRecorder";
import NoBranchesModal from "./NoBranchesModal";
import PromptEditor, { FocusableRef } from "./PromptEditor";

import { PlanningAnswer } from "../components/tech-plan/ApiTypes";
import Features from "../data/Features";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import {
    SessionStatus,
    Turn,
    useCanCancelSolve,
    useCanSolve,
    useCancelSolve,
    useComments,
    useCreateAndPlan,
    useCreateAndSolve,
    usePlan,
    useRevertToTurn,
    useSession,
    useSessionNLText,
    useSessionStatus,
    useSolve,
    useTurns,
    useUpdateSessionStatus,
} from "../data/SolverSession";

interface NaturalLanguageInputProps {
    notification: NotificationInstance;
    editingTurn: Turn | null;
    onCancelEditingTurn: () => void;
    sessionType: "planning" | "solving" | undefined;
    techPlanAnswers?: PlanningAnswer[];
}

const NaturalLanguageInput: React.FC<NaturalLanguageInputProps> = ({
    notification,
    editingTurn,
    onCancelEditingTurn,
    sessionType,
    techPlanAnswers,
}) => {
    const session = useSession();
    const sessionNLText = useSessionNLText();
    const sessionStatus = useSessionStatus();
    const updateSessionStatus = useUpdateSessionStatus();
    const solve = useSolve();
    const createAndSolve = useCreateAndSolve();
    const plan = usePlan();
    const createAndPlan = useCreateAndPlan();
    const canSolve = useCanSolve();
    const cancelSolve = useCancelSolve();
    const canCancelSolve = useCanCancelSolve();
    const comments = useComments();
    const revertToTurn = useRevertToTurn();
    const turns = useTurns();

    const { currentUser, activeRepo } = useSolverInterfaceContext();

    const promptEditorRef = useRef<FocusableRef>(null);

    // Cache the prompt so that it can be updated when we solve.
    const [cachedNLText, setCachedNLText] = useState<string>("");
    const [showNoBranchesModal, setShowNoBranchesModal] = useState<boolean>(false);
    const [creatingInitialCommit, setCreatingInitialCommit] = useState<boolean>(false);
    const [hasBranches, setHasBranches] = useState<boolean | null>(null);

    useEffect(() => {
        if (!!editingTurn) {
            setCachedNLText(editingTurn.nl_text);
            promptEditorRef.current?.focus();
        } else {
            setCachedNLText(sessionNLText);
        }
    }, [sessionNLText, editingTurn]);
    useEffect(() => {
        promptEditorRef.current?.focus();
    }, [session?.session_id]);

    const [resolveReadyState, setResolveReadyState] = useState<(() => void) | null>(null);

    // Watch session status and resolve our promise when ready
    useEffect(() => {
        if (sessionStatus === SessionStatus.READY && resolveReadyState) {
            resolveReadyState();
            setResolveReadyState(null);
        }
    }, [sessionStatus, resolveReadyState]);
    const onPromptChange = (value: string | undefined) => setCachedNLText(value || "");

    const handleTranscriptionComplete = async (transcribedText: string) => {
        setCachedNLText(transcribedText);
        // Automatically submit the transcribed text
        if (!session) {
            if (activeRepo) {
                createAndSolve(transcribedText, activeRepo.org, activeRepo.name, activeRepo.default_branch);
            } else {
                throw new Error("No active repository selected");
            }
        } else {
            solve(transcribedText, comments);
        }
    };

    const buildSolveAndOrPlanButtons = () => {
        if (sessionType === "planning") {
            return buildPlanButton();
        } else if (sessionType === "solving") {
            return buildSolveButton();
        } else {
            return (
                <>
                    {buildSolveButton()}
                    {Features.isProjectsUIEnabled() && buildPlanButton()}
                </>
            );
        }
    };

    const buildButtons = () => {
        switch (sessionStatus) {
            case SessionStatus.READY:
                return buildSolveAndOrPlanButtons();
            case SessionStatus.SUBMITTING_SOLVE:
                return buildSubmittingSolveButton();
            case SessionStatus.PENDING:
            case SessionStatus.SOLVING:
                return buildCancelSolveButton();
            case SessionStatus.SUBMITTING_CANCEL:
                return buildCancellingSolveButton();
            case SessionStatus.ARCHIVED:
                return buildArchivedButton();
            default:
                return buildSolveAndOrPlanButtons();
        }
    };

    const buildSolveButton = () => {
        const buttonText =
            comments.length > 0 && !editingTurn
                ? `Solve with ${comments.length} comment${comments.length > 1 ? "s" : ""}`
                : "Solve";

        return (
            <Button
                id="tour-solve-button"
                className="solve-button"
                type="primary"
                onClick={(e) => {
                    onSolve();
                    e.stopPropagation();
                }}
                disabled={!canSolve(cachedNLText, comments, undefined)}
            >
                <FunctionOutlined /> {buttonText}
            </Button>
        );
    };

    const buildPlanButton = () => {
        return (
            <Button
                id="tour-plan-button"
                className="solve-button"
                type="primary"
                onClick={(e) => {
                    onPlan();
                    e.stopPropagation();
                }}
                disabled={!canSolve(cachedNLText, [], techPlanAnswers)}
            >
                <ApartmentOutlined /> Plan
            </Button>
        );
    };

    const buildSubmittingSolveButton = () => {
        return (
            <Button className="solve-button" type="primary" disabled={true}>
                <LoadingOutlined /> Submitting
            </Button>
        );
    };

    const buildCancelSolveButton = () => {
        return (
            <Button
                className="solve-button"
                onClick={(e) => {
                    onCancelSolve();
                    e.stopPropagation();
                }}
                disabled={!canCancelSolve()}
            >
                <LoadingOutlined /> Cancel
            </Button>
        );
    };

    const buildCancellingSolveButton = () => {
        return (
            <Button className="solve-button" disabled={true}>
                <LoadingOutlined /> Cancelling
            </Button>
        );
    };

    const buildArchivedButton = () => {
        return (
            <Tooltip title="This session has been archived and can no longer be solved.">
                <Button className="solve-button" disabled={true}>
                    Archived
                </Button>
            </Tooltip>
        );
    };

    // Reset hasBranches when activeRepo changes
    useEffect(() => {
        setHasBranches(null);
    }, [activeRepo]);

    const onSolve = async () => {
        if (!currentUser || !activeRepo) return;

        if (editingTurn) {
            if (sessionStatus !== SessionStatus.READY) {
                return; // Don't proceed if we're not in READY state
            }
            // When editing, first revert to the turn before the edited one
            const prevTurn = turns.find((t) => t.idx === editingTurn.idx - 1);
            await revertToTurn(prevTurn?.id ?? null);
            updateSessionStatus(SessionStatus.PENDING);

            // Create a promise that resolves when the session is ready
            const waitForReady = new Promise<void>((resolve, reject) => {
                if (sessionStatus === SessionStatus.READY) {
                    resolve();
                } else {
                    setResolveReadyState(() => resolve);
                    // Timeout after 10 seconds
                    setTimeout(() => reject(new Error("Timeout waiting for ready state")), 10000);
                }
            });

            try {
                await waitForReady;
                // Then solve with the new text, but without any comments since we're just saving changes
                await solve(cachedNLText, []);
                onCancelEditingTurn();
            } catch (error) {
                console.error("Failed waiting for session ready state:", error);
                notification.error({ message: "Failed to edit turn: timeout waiting for ready state" });
            } finally {
                setResolveReadyState(null);
            }
            return;
        }

        if (!session) {
            // Only check branches if we haven't checked before or if we previously found no branches
            if (hasBranches === null || hasBranches === false) {
                try {
                    const branches = await getBranches(activeRepo.org, activeRepo.name, 250);
                    const hasAnyBranches = branches.length > 0;
                    setHasBranches(hasAnyBranches);

                    if (!hasAnyBranches) {
                        setShowNoBranchesModal(true);
                        return;
                    }
                } catch (error) {
                    notification.error({
                        message: "Failed to check repository branches",
                        placement: "bottomRight",
                    });
                    return;
                }
            }
            createAndSolve(cachedNLText, activeRepo.org, activeRepo.name, activeRepo.default_branch);
        } else {
            solve(cachedNLText, comments);
        }
    };

    const onPlan = async () => {
        if (!currentUser || !activeRepo) return;

        if (!session) {
            // Only check branches if we haven't checked before or if we previously found no branches
            if (hasBranches === null || hasBranches === false) {
                try {
                    const branches = await getBranches(activeRepo.org, activeRepo.name, 250);
                    const hasAnyBranches = branches.length > 0;
                    setHasBranches(hasAnyBranches);

                    if (!hasAnyBranches) {
                        setShowNoBranchesModal(true);
                        return;
                    }
                } catch (error) {
                    notification.error({
                        message: "Failed to check repository branches",
                        placement: "bottomRight",
                    });
                    return;
                }
            }
            createAndPlan(cachedNLText, activeRepo.org, activeRepo.name, activeRepo.default_branch);
        } else {
            plan(cachedNLText, techPlanAnswers);
        }
    };

    const handleCreateInitialCommit = async () => {
        if (!activeRepo) return;

        setCreatingInitialCommit(true);
        try {
            await pushInitialCommit(activeRepo.org, activeRepo.name);
            setShowNoBranchesModal(false);
            setHasBranches(true); // We know we just created a branch
            createAndSolve(cachedNLText, activeRepo.org, activeRepo.name, activeRepo.default_branch);
            notification.success({
                message: "Initial commit created",
                description: `You can now Solve in ${activeRepo.name}`,
                placement: "bottomRight",
            });
        } catch (error) {
            notification.error({
                message: "Failed to create initial commit",
                description: error instanceof Error ? error.message : "An unknown error occurred",
                placement: "bottomRight",
            });
        } finally {
            setCreatingInitialCommit(false);
        }
    };

    const onCancelSolve = () => {
        if (!canCancelSolve()) return;

        cancelSolve();
    };

    const onPromptEditorKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (processSolveHotkey(e)) return;
    };

    const processSolveHotkey = (e: React.KeyboardEvent<HTMLElement>): boolean => {
        if ((e.ctrlKey || e.metaKey) && e.key === "Enter" && canSolve(cachedNLText, comments, techPlanAnswers)) {
            if (sessionType === "planning") {
                onPlan();
            } else {
                onSolve();
            }

            (e.target as HTMLTextAreaElement).blur();
            e.preventDefault();
            return true;
        }

        return false;
    };

    return (
        <div
            className="nl-text-container"
            onClick={() => {
                promptEditorRef.current?.focus();
            }}
        >
            <PromptEditor
                ref={promptEditorRef}
                value={cachedNLText}
                onChange={onPromptChange}
                onKeyDown={onPromptEditorKeyDown}
                placeholder="Write a task, pose a problem, or ask a question"
                disabled={session ? !session.allowModification(currentUser?.id) : false}
            />
            <div className="solve-button-container">
                <div className="audio-recorder-container">
                    {(!session || session.allowModification(currentUser?.id)) && (
                        <CustomAudioRecorder onTranscriptionComplete={handleTranscriptionComplete} />
                    )}
                </div>
                {buildButtons()}
            </div>
            <NoBranchesModal
                isOpen={showNoBranchesModal}
                onClose={() => setShowNoBranchesModal(false)}
                onCreateInitialCommit={handleCreateInitialCommit}
                isCreating={creatingInitialCommit}
            />
        </div>
    );
};

export default NaturalLanguageInput;
